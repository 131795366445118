export const clientEndpoint = {
	// 全員可能
	login: "/login",
	logout: "/logout",
	menu: "/menu",

	passwordResetMail: "/password-reset-mail",
	successPasswordResetMail: "/success-password-reset-mail",
	passwordReset: "/password-reset/:hash",
	successPasswordReset: "/success-password-reset",

	account: "/account",
	activity: "/activity",

	activityCreate: "/activity/:activityId",

	activityStatus: "/activity-status/:activityId",
	job: "/activity/:activityId/job",
	jobAll: "/job-all",
	jobSearch: "/job-search",
	jobDownload: "/job-download",
	jobCreate: "/activity/:activityId/job/:jobId",
	jobCreateRetest: "/activity/:activityId/retest/:retestJobId",

	question: "/question/:activityId/:jobId",

	jobStatus: "/job-status/:activityId/:jobId",
	jobDetail: "/job-detail/:activityId/:jobId",
	invoice: "/invoice/:activityId/:jobId",
	concept: "/concept/:activityId/:jobId",
	conceptPreview: "/concept_preview/:activityId/:jobId",
	quota: "/quota/:activityId/:jobId",
	application: "/application/:activityId/:jobId",
	approval: "/approval/:activityId/:jobId",
	preview: "/preview/:activityId/:jobId",

	// レポートページ
	outline: "/report/:activityId/:jobId/outline",
	exectiveReport: "/report/:activityId/:jobId/executive",
	evaluationReport: "/report/:activityId/:jobId/evaluation",
	simulationReport: "/report/:activityId/:jobId/simulation",
	conceptReport: "/report/:activityId/:jobId/concept",

	// 以下ページは廃止
	conceptEvaluationReport: "/report/:activityId/:jobId/concept-evaluation",
	purchaseIntentProfileReport: "/report/:activityId/:jobId/purchase-intent-profile",
	downloadReport: "/report/:activityId/:jobId/download",
	skuReport: "/report/:activityId/:jobId/sku",

	// ここまで廃止。
	resultByTargetReport: "/report/:activityId/:jobId/result-by-target",
	oaReport: "/report/:activityId/:jobId/oa",
	keyMeasurementsReport: "/report/:activityId/:jobId/key-mesurements",

	// 固定ぱす
	permissionDenied: "/permission-denied",
	invalidUrl: "/invalid-url",

	aggregate: "/aggregate",
	aggtegateCreate: "/aggregate/:jobId/:aggregateId",
	aggtegateView: "/aggregate-view/:jobId/:aggregateId",

	// oshirase 設定画面
	infoEditPage: "/info/edit",

	// PDF
	guidelines: "/pdf/guidelines.pdf",
	manual: "/pdf/manual.pdf",
	simulationManual: "/pdf/simulation-manual.pdf",
	simulationExplain: "https://shiseido.box.com/s/ofq0cwuo1h5dkyubrq7dtrdl3jayoo5e",
} as const;

export type ClientEndpoint = keyof typeof clientEndpoint;
export type ClientEndpointPath = (typeof clientEndpoint)[ClientEndpoint];
